export default
{

    projects: [
        {
            name: 'String Parse',
            link: 'https://strparse.com',
            cms: 'Laravel',
            cms_logo: require('./laravel-logo.png'),
            description: 'Personal project which host string manipulation tools and calculators for day to day work.',
            preview: require('./strparse.png'),
            tech: ['PHP','SCSS','Javascript']
        },
        {
            name: 'Tommy Hilfiger GR',
            link: 'https://gr.tommy.com/',
            cms: 'Magento 2',
            cms_logo: require('./magento-logo.png'),
            description: 'Worked on world wide known fashion brand Tommy Hilfiger. Consultation, platform knowledge and best practices were key for success of the brand in Greece. Continuous development of ERP Integration, automation processes and frontend tasks.',
            preview: require('./tommy.png'),
            tech: ['PHP','SCSS','Javascript', 'Magento Rest API', 'Swagger', 'Frontend Optimizations']
        },
        {
            name: 'Calvin Klein GR',
            link: 'https://calvinklein.gr/',
            cms: 'Magento 2',
            cms_logo: require('./magento-logo.png'),
            description: 'Worked towards the launch of fashion brand Calvin Klein in Greece. Tight deadlines and high customer expectations were one of' +
                ' the many challenges to face with the team that got involded. We overcome those challenges, met client expectations and delivered high quality Magento eshop. ERP Integrations, automations and frontend ',
            preview: require('./calvin.png'),
            tech: ['PHP','SCSS','Javascript', 'Magento Rest API', 'Swagger']
        },
        {
            name: 'Funky Buddha',
            link: 'https://www.funky-buddha.com/',
            cms: 'Magento 2',
            cms_logo: require('./magento-logo.png'),
            description: 'ERP Integrations, automations and frontend tasks',
            preview: require('./funky.png'),
            tech: ['PHP','SCSS','Javascript', 'Magento Rest API', 'Swagger']
        },
        {
            name: 'Flowernet',
            link: 'https://www.flowernet.gr/',
            cms: 'Magento',
            cms_logo: require('./magento-logo.png'),
            description: 'Flowernet is an online flower shop featuring hundreds of unique products. Full frontend development on this project aswell some backend functionality',
            preview: require('./flowernet.png'),
            tech: ['PHP','SCSS','Javascript', 'SEO']
        },
        // {
        //     name: 'Lakiotis',
        //     link: 'https://www.lakiotis.gr/',
        //     cms: 'Magento',
        //     cms_logo: require('./magento-logo.png'),
        //     description: 'Responsible for frontend development aswell as server side optimization that resulted on a 180~240 ms load time on all pages.',
        //     preview: require('./lakiotis.png'),
        //     tech: ['PHP','LESS', 'Javascript','r.js optimizer']
        //
        // },
        // {
        //     name: 'Helma',
        //     link: 'http://helma.gr/',
        //     cms: 'Magento',
        //     cms_logo: require('./magento-logo.png'),
        //     description: 'Frontend development aswell as challenging backend tasks to bring the catalog management on clients needs.',
        //     preview: require('./helma.png'),
        //     tech: ['PHP','LESS', 'Javascript','Animations']
        //
        // },
        // {
        //     name: 'Plantshed',
        //     link: '#',
        //     cms: 'Magento',
        //     cms_logo: require('magento-logo.png'),
        //     description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent interdum, quam posuere fringilla iaculis, turpis magna dignissim dui, et eleifend mi massa non urna.',
        //     preview: 'https://via.placeholder.com/500x800',
        //     tech: ['PHP','TEST']
        //
        // },
        // {
        //     name: 'Plantshed',
        //     link: '#',
        //     cms: 'Magento',
        //     cms_logo: require('magento-logo.png'),
        //     description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent interdum, quam posuere fringilla iaculis, turpis magna dignissim dui, et eleifend mi massa non urna.',
        //     preview: 'https://via.placeholder.com/500x800',
        //     tech: ['PHP','TEST']
        //
        // }
    ]

}