import React,  {Component} from "react";


class Logo extends React.Component
{


    render() {


        return (
            <span>VN</span>
        )
    }



}

export default  Logo
