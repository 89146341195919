import React,  {Component} from "react";
import data from "../../Data/work-data";
import {useSpring, animated} from "react-spring";
import { Spring } from "react-spring/renderprops";
import { InView } from 'react-intersection-observer'
import Title from "../Layout/Title";
import ProjectBox from "./SubComponents/ProjectBox";


class Work extends React.Component
{

    state = {
        _showElement: false
    }

    onInViewChange = inview => {
        if (!this.state._showElement && inview) this.setState({ _showElement: true })

    }

    render() {
        return (
            <InView   tag="div"  onChange={this.onInViewChange}>
                <Spring  to={{ opacity: this.state._showElement ? 1 : 0 }} delay={500}>
                    {props => (
                        <animated.div id="projects-wrapper" style={props}>
                            <Title num={"2."} name={"Here are some projects"}/>
                            <div className="projects">
                                {data.projects.map((context,index)=>(
                                    <ProjectBox
                                    name={context.name}
                                    cms={context.cms}
                                    logo={context.cms_logo}
                                    description={context.description}
                                    tech={context.tech}
                                    link={context.link}
                                    preview={context.preview}
                                    />
                                ))}


                            </div>
                            <div className="content-wrapper">
                                <div className="content">
                                 
                                </div>
                            </div>
                        </animated.div>

                    )}
                </Spring>
            </InView>



        )
    }



}

export default  Work
