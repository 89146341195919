import React,  {Component} from "react";
import data from "../../Data/skills-data";
import {useSpring, animated} from "react-spring";

import { Spring } from "react-spring/renderprops";
import { InView } from 'react-intersection-observer'
import Title from "../Layout/Title";
import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';



class Skills extends React.Component
{

    state = {
        _showElement: false
    }

    onInViewChange = inview => {
        if (!this.state._showElement && inview) this.setState({ _showElement: true })

    }

    render() {
        return (
            <InView   tag="div"  onChange={this.onInViewChange}>
                <Spring  to={{ opacity: this.state._showElement ? 1 : 0 , position:'relative',bottom: this.state._showElement ? '0' : '-80px' }} delay={500}>
                    {props => (
                        <animated.div id="skills-wrapper" style={props}>
                            <Title num={"3."} name={"Technologies I use"}/>
                            <Carousel
                                infinite
                                arrows
                                slidesPerPage={1}
                            >
                                {data.skills.map((context,index)=>(
                                    <div className="skill-box">
                                        <div className="image-wrapper">
                                            <img data-src={context.logo} alt=""/>
                                        </div>
                                        <div className="info-wrapper">
                                            <h3>{context.name}</h3>
                                            <p>{context.description}</p>
                                        </div>
                                    </div>
                                    ))}
                            </Carousel>

                        </animated.div>

                    )}
                </Spring>
            </InView>



        )
    }



}

export default  Skills
