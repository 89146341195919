export default
{

    navigation: [
        {
            name: 'My Story',
            item: '#about-wrapper',
        },
        {
            name: 'Portfolio',
            item: '#projects-wrapper',
        },
        {
            name: 'Skills',
            item: '#skills-wrapper',
        },
        {
            name: 'Contact',
            item: '#contact-wrapper',
        }
    ]

}