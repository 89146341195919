import React, {Component} from "react";
import data from '../../Data/contact-data';
import {InView} from "react-intersection-observer";
import {Spring} from "react-spring/renderprops-universal";
import {animated} from "react-spring";
import Githubsvg from "../../Data/Githubsvg";
import Instagramsvg from "../../Data/Instagramsvg";
import LinkdinSvg from "../../Data/LinkdinSvg";

class Contact extends Component {
    state = {
        _showElement: false
    }

    onInViewChange = inview => {
        if (!this.state._showElement && inview) this.setState({ _showElement: true })

    }
    render() {
        return (
            <InView   tag="div"  onChange={this.onInViewChange}>
                <Spring  to={{
                    opacity: this.state._showElement ? 1 : 0,
                    bottom: this.state._showElement ? 0 : '50px'

                }} delay={500}>
                    {props => (
                        <animated.div id="contact-wrapper" style={props}>
                                <h3><span>4.</span> Get in Touch</h3>
                                <p>You have a request or just wanna get in touch?</p>
                                <a href={`maito:${data.email}`}>{data.email}</a>
                                <div className="socials">
                                    <a target="_blank" href={data.linkedin}><LinkdinSvg/></a>
                                    <a target="_blank" href={data.github}><Githubsvg/></a>
                                    <a target="_blank"  href={data.instagram}><Instagramsvg/></a>
                                </div>
                        </animated.div>

                    )}
                </Spring>
            </InView>

        );
    }
}

export default Contact;
