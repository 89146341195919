import React,  {useState} from "react";
import data from "../../Data/header-data";
import {useSpring, animated} from "react-spring";
import Logo from '../../Data/Logo';
import MenuSvg from "../../Data/MenuSvg";



function Header() {
    const props = useSpring({opacity: 1, marginLeft:0,delay: 1000,   from: {opacity: 0, marginLeft:-150}});
    return (
        <div className="header">
            <div className="logo">
                <Logo/>
            </div>
            <MenuSvg/>

            <div className="nav-items-wrapper">
                {data.navigation.map((link,index)=>(
                    <animated.div key={index} style={props} className="sidenav-item"><a data-scroll={link.item} href="javascript:void(0)"><span>{index + 1}.</span>{link.name}</a></animated.div>
                ))}
                <span className="menu-toggler">X</span>

            </div>

        </div>
    );
}



setTimeout(function () {
    let elements = document.querySelectorAll('.header .sidenav-item a');
    let menuToggler            = document.querySelector('.header svg');
    let menuTogglerClose = document.querySelector('.nav-items-wrapper .menu-toggler');
    let menu                             = document.querySelector('.nav-items-wrapper');


    elements.forEach(function (el) {
        el.addEventListener('click', function () {
            let dataScroll = el.getAttribute('data-scroll');
            let elementToScroll = document.querySelector(dataScroll);
            elementToScroll.scrollIntoView({behavior: "smooth"});
            menu.classList.remove('active');
        });
    });


    menuToggler.addEventListener('click',function () {
        menu.classList.toggle('active');
    });
    menuTogglerClose.addEventListener('click',function () {
        menu.classList.toggle('active');
    });

},100);



export default  Header
