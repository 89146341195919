import React,  {Component} from "react";
import {useSpring, animated} from "react-spring";
import { Spring } from "react-spring/renderprops";
import { InView } from 'react-intersection-observer'
import BuildWith  from "./BuildWith";
import Cms  from "./Cms";
import LinkIcon from "../../../Data/ExternalLinkSvg";


class ProjectBox extends React.Component
{
    state = {
        _showElement: false
    }

    onInViewChange = inview => {
        if (!this.state._showElement && inview) this.setState({ _showElement: true })
        else{
            this.setState({ _showElement: false })
        }
    }

    render() {

        return (
            <InView   tag="div" className="project-box"  onChange={this.onInViewChange}>
                <Spring  to={{ opacity: this.state._showElement ? 1 : 0, position:'relative',bottom: this.state._showElement ? '0' : '-80px'}} delay={500}>
                    {props => (
                        <animated.div  style={props}>
                            <div className="image-wrapper">
                                <img data-src={this.props.preview} alt={this.props.name}/>
                            </div>
                            <div className="content-wrapper">
                                <span className="small-text">Highlighted Project</span>
                                <h3>{this.props.name}</h3>
                                <Cms cms={this.props.cms} logo={[this.props.logo]}/>
                                <div className="description">
                                    <p>{this.props.description}</p>
                                </div>
                                <BuildWith tech={[this.props.tech]}/>
                                <LinkIcon link={this.props.link} />
                            </div>
                        </animated.div>

                    )}
                </Spring>
            </InView>



        )
    }



}

export default  ProjectBox
