import React,  {Component} from "react";


class Cms extends React.Component
{


    render() {


        return (
            <div className="cms-logo">
                <span>{this.props.cms}</span><img data-src={this.props.logo} alt={this.props.cms}/>
            </div>
        )
    }



}

export default  Cms
