export default
{

    skills: [
        // Add Or Remove The Link Accordingly
        {
            name: 'PHP',
            logo: require('./skills/php.png'),
            description: 'PHP (recursive acronym for PHP: Hypertext Preprocessor) is a widely-used open source general-purpose scripting language that is especially suited for web development and can be embedded into HTML.'
        },
        {
            name: 'HTML',
            logo: require('./skills/html5.png'),
            description: 'With HTML5, browsers like Firefox, Chrome, Explorer, Safari and more, can know how to display a particular web page, know where the elements are, where to put the images and where to place the text.'
        },
        {
            name: 'CSS',
            logo: require('./skills/css3.png'),
            description: 'CSS describes how HTML elements are to be displayed'
        },
        {
            name: 'Javascript',
            logo: require('./skills/javascript.png'),
            description: 'JavaScript is a scripting language that enables you to create dynamically updating content, control multimedia, animate images, and pretty much everything else.'
        },
        {
            name: 'SCSS',
            logo: require('./skills/scss.png'),
            description: 'SCSS is a preprocessor which lets you use features that aren’t a part of the wider CSS standard yet, and provides better workflows for maintaining your stylesheets.'
        },
        {
            name: 'LESS',
            logo: require('./skills/less.png'),
            description: 'LESS allows you to write CSS in a smarter way by combining functions, mixins, operations and more. Lke SCSS, its a preprocessor '
        },
        {
            name: 'GIT',
            logo: require('./skills/git.png'),
            description: 'Git is a free and open source distributed version control system designed to handle everything from small to very large projects with speed and efficiency. '
        },
        {
            name: 'MySQL',
            logo: require('./skills/mysql.png'),
            description: 'MySQL, the most popular Open Source SQL database management system, is developed, distributed, and supported by Oracle Corporation.'
        },
        {
            name: 'Terminal Skills',
            logo: require('./skills/ssh.png'),
            description: 'The SSH protocol (also referred to as Secure Shell) is a method for secure remote login from one computer to another.'
        },

    ]

}