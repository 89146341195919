import React,  {Component} from "react";


class ExternalLinkSvg extends React.Component
{


    render() {


        return (
            <a target={'_blank'} href={this.props.link}>
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg"xlinkHref="http://www.w3.org/1999/xlink" width="50" height="50" viewBox="0 0 16 16">
                    <path fill="#444444" d="M14 16v-11l-1 1v9h-12v-12h9l1-1h-11v14z"></path>
                    <path fill="#444444" d="M16 0h-5l1.8 1.8-6.8 6.8 1.4 1.4 6.8-6.8 1.8 1.8z"></path>
                </svg>
            </a>
        )
    }



}

export default  ExternalLinkSvg
