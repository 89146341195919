import React, { Component } from "react";
import './css/App.css';

import Header from "./components/Layout/Header";
import Intro from "./components/Sections/Intro";
import Work from "./components/Sections/Work";
import Skills from "./components/Sections/Skills";
import About from "./components/Sections/About";
import Contact from "./components/Sections/Contact";

document.addEventListener('DOMContentLoaded', function (){
    const images = document.querySelectorAll("[data-src]");
    function preloadImages(img){
        const src = img.getAttribute('data-src');
        if(!src){
            return
        }
        img.src = src;
    }

    const imgOptions = {
        threshold: 0,
        rootMargin: "0px 0px 200px 0px"
    };

    const imgObserver = new IntersectionObserver((entries,imgObserver) => {
        entries.forEach(entry => {
            if(!entry.isIntersecting) {
                return;
            }
            else{
                preloadImages(entry.target);
                imgObserver.unobserve(entry.target);
            }
        })
    }, imgOptions);

    images.forEach(image => {
        imgObserver.observe(image);
    });
});




class App extends Component {
    render() {
        return (
            <div>
                <div id="navigation" className="navigation">
                    <Header/>

                </div>
                <div className="page-wrapper">
                    <Intro/>
                    <About/>
                    <Work/>
                    <Skills/>
                    <Contact/>
                </div>
            </div>
        );
    }
}


export default App;
