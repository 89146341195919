import React,  {Component} from "react";
import data from "../../Data/global";
import { Spring } from "react-spring/renderprops";
import { InView } from 'react-intersection-observer'
import {useSpring, animated} from "react-spring";
import Title from "../Layout/Title";
import image from "../../Data/profile.jpg";



class About extends React.Component
{

    state = {
        _showElement: false
    }

    onInViewChange = inview => {
        if (!this.state._showElement && inview) this.setState({ _showElement: true })
        else{
            this.setState({ _showElement: false })
        }
    }

    render() {
        return (
            <InView   tag="div"  onChange={this.onInViewChange}>
                <Spring  to={{ opacity: this.state._showElement ? 1 : 0, position:'relative', bottom: this.state._showElement ? '0' : '-80px'
                }} delay={500}>
                    {props => (
                        <animated.div id="about-wrapper" style={props}>
                            <Title num={"1."} name={"My story"}/>
                            <div className="content-wrapper">
                                <div className="content">
                                    {data.description.map((context,index)=>(
                                        <p>{context.content}</p>
                                    ))}
                                </div>
                                <div className="image-wrapper">
                                    <img data-src={image} alt=""/>
                                </div>
                            </div>
                        </animated.div>

                    )}
                </Spring>
            </InView>


        )
    }



}

export default  About
