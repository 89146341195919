import React,  {Component} from "react";


class Title extends React.Component
{


    render() {
        return (
            <div id="title-wrapper">
                <h3><span>{this.props.num}</span>{this.props.name}</h3>
            </div>
        )
    }
}

export default  Title
