import React,  {Component} from "react";
import data from "../../../Data/work-data";


class BuildWith extends React.Component
{


    render() {
        let techs = this.props.tech[0];


        return (
            <div className="build-with">
                {techs.map(tech => <span>{tech}</span>)}
            </div>
        )
    }



}

export default  BuildWith
