export default

{
    name:  'Vasilis Neris',
    tagLine: 'Full Stack Developer / Magento Developer',
    tagLine2: 'I Develop stuff.',
    shortDescription: 'Experienced Magento Developer with demonstrated 6 years of work experience in regards to retail, marketing and e commerce industries.',
    profile: "profile.jpg",
    description: [
        {
            content:  `I started as a web designer with no coding knowledge at all in 2016 other than what is HTML.`
        },
        {
            content:  `My only knowledge was e-commerce that I gained by working on retail flower shop with an eshop.`
        },
        {
            content:  `My company at the time was working on Magento 1 projects. Somedays I was asking our lead developer... -"can I help with something?". The answer was no until one day he said...`
        },
        {
            content:  `-"Maybe I have something for you"`
        },
        {
            content:  `I was introduced to Magento and from that moment I was hooked into coding.`
        },
        {
            content:  `I started staying up late nights to understand the platform and learn PHP, Javascript and what not. What are web services, what are Integrations etc...`
        },
        {
            content:  `After 6 years in the development world I've grown a very useful habbit which is to ask my self "How something was made" or "How can this be made" and try to reverse engineer the logic on the fly.`
        },
        {
            content:  `Now my main focus is to utilize all resources available in order to create, optimize and maintain the best possible tools/solutions for each industry to efficiently cover needs and contribute to the growth and success of the brand.`
        },
        {
            content:  `Good analytic and problem solving skills, great understanding of data structures, client oriented mentality.`
        },
        {
            content:  `Currently i’m working as Magento 2 developer.`
        }
    ]
}