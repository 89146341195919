import React, {Component} from "react";
import data from '../../Data/global';
import cv from '../../Data/Vasilis_Resume.pdf'


class Intro extends Component {
    render() {
        return (
            <section id="intro">
                <span className="small-text">Hello there, my name is</span>
                <h1>{data.name}.</h1>
                <h2>{data.tagLine2}</h2>
                <p>{data.shortDescription}</p>
                <div className="actions">
                    <a href="#contact-wrapper">Contact</a>
                    <a target="_blank" href={cv}>Resume</a>
                </div>


            </section>
        );
    }
}

export default Intro;
